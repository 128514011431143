// 
// $('.fa-star').click(function() {
//   $('.fa-star').toggleClass('fa-star-o');
// });

/* 1. Visualizing things on Hover - See next part for action on click */
$('#stars li').on('mouseover', function() {
    var onStar = parseInt($(this).data('value'), 10); // The star currently mouse on

    // Now highlight all the stars that's not after the current hovered star
    $(this).parent().children('li.star1').each(function(e) {
        if (e < onStar) {
            $(this).addClass('hover');
        } else {
            $(this).removeClass('hover');
        }
    });

}).on('mouseout', function() {
    $(this).parent().children('li.star1').each(function(e) {
        $(this).removeClass('hover');
    });
});

/* 2. Action to perform on click */
$('#stars li').on('click', function() {
    var onStar = parseInt($(this).data('value'), 10); // The star currently selected
    var stars = $(this).parent().children('li.star1');

    for (i = 0; i < stars.length; i++) {
        $(stars[i]).removeClass('selected');
    }

    for (i = 0; i < onStar; i++) {
        $(stars[i]).addClass('selected');
    }


});
$(".checkbox_btn").click(function() {
    if ($('.selected') == true) {
        $("#modify_styling2").addClass("modify_styling2");
        $("#feedback_submitted").addClass("feedback_submitted");
    }

});

function myFunction() {
    document.getElementById("panel").style.display = "block";
    // (".slick-dots li").trigger("click")
    // $(".slick-dots li").click()

}
$(function() {
    $('#seldat').on('click', function() {
        // $(this).closest('#hauto').css({'height': 'auto', 'color' : 'red'});
        $(this).closest('#hauto').addClass("block");
    })
});

$("#start").click(function() {
    $("#updatepanel").addClass("block");
});
// $("#seldat").click(function(){
//   $("#hauto").addClass("block");
// });
function sidebarToggel(){
        setTimeout(function() {
            $("ul.slick-dots li").trigger('click');
        }, 10);
        var trigger = $('.hamburger'),
            overlay = $('.overlay'),
            isClosed = true;
        $('[data-toggle="offcanvas"]').click(function() {
            $(".overlay").animate({
                "opacity": "1",
                "transition": "all 2s ease-out"
            });
        });
        trigger.click(function() {
            hamburger_cross();
        });
    
        function hamburger_cross() {
    
            if (isClosed == true) {
                overlay.show();
    
                // $(".box").animate({width: 0});
                trigger.addClass('is-open');
                trigger.removeClass('is-closed');
                isClosed = false;
            } else {
                overlay.hide({
                    direction: 'left'
                }, 1400);
                trigger.addClass('is-closed');
                trigger.removeClass('is-open');
    
                isClosed = true;
            }
        }
    
        $('[data-toggle="offcanvas"]').click(function() {
            $('#wrapper').toggleClass('toggled');
        });
        $(".radio_btn").click(function() {
            $("#radio_btn_submitted").addClass("radio_btn_submitted");
            $("#modify_styling").addClass("modify_styling");
        });
        $(".checkbox_btn").click(function() {
            $("#modify_styling2").addClass("modify_styling2");
            $("#feedback_submitted").addClass("feedback_submitted");
        });
    
        $('.responsive').slick({
            dots: true,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            speed: 300,
            responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 769,
                    variableWidth: true,
                    settings: {
                        slidesToShow: 1.2,
                        slidesToScroll: 1,
                        // infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    variableWidth: true,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        // infinite: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        dots: true,
                        slidesToShow: 1.15,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
        // $('#input').datetimepicker({
        //     footer: true,
        //     modal: true,
        //     format: 'dd mmmm, yyyy'
        // });
      
        if ( $(window).width() < 720 ) {      
          //Add your javascript for large screens here 
        //   alert('480px is here');
    
        $( ".btn-tgclose" ).click(function() {
        //   $( ".navbar" ).trigger( "focus" );
    
        $(".slick-dots li").trigger('click');
        // $( ".chat-body" ).trigger( "focus" );
        });
        // $(".slick-dots li").trigger('click');
        } 
    
}
// $("body").on('DOMNodeInserted','#modify_styling2', function() {
//     $('app-form-viewer:has(input.radio-custom)').addClass('radioButtonContainer');
// });